@import "@/assets/scss/style.scss";

.c-settings {
    padding: 24px;
    margin: auto;
    width: 50%;
    min-width: 40vh;
    margin-top: 2rem;

    &--homepage {
        width: auto;
        min-width: auto;
    }
    &__segment {
        padding: 20px;
        background-color: #ffffff;
        border-radius: 12px;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 16px;
        }
    }

    &__menu-list {
        background-color: #ffffff;
        border-radius: 10px;
    }

    &__list-fit {
        width: 100%;
        height: 100%;
    }

    &__list-item {
        padding: 0;
    }

    &__list-link {
        color: #262626;
        height: 60px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
            'Helvetica Neue', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
    }

    &__list-icon {
        filter: invert(61%) sepia(9%) saturate(24%) hue-rotate(314deg) brightness(89%) contrast(85%);
        width: 19px;
        height: 15px;
    }

    &__list-arrow {
        width: 10px;
        height: 10px;
    }

    &__list-text {
        margin-left: 20px;
    }

    &__list-arrow {
        widows: 10%;
    }
}
